export enum Page {
  Home = "home",
  Portfolio = "portfolio",
  Resume = "resume",
  PogoRogue = "pogoRogue",
  Fido = "fido",
  Blindside = "blindside",
  OhgjEntries = "ohgjEntries",
  Wormwood = "wormwood",
  Error = "error",
}
